import styled from 'styled-components';

const PartnershipsStyle = styled.div`

  h2 {
    margin-bottom: 40px;
  }
`;

export default PartnershipsStyle;
